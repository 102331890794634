import React, { useContext, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import { MdKeyboardArrowUp } from "react-icons/md";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

const Faq = ({ heading, page, image, imageAlt, imageHeight }) => {
	const data = useStaticQuery(graphql`
		query {
			allWpFaq {
				nodes {
					faqFields {
						answer
						page
						question
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const height = imageHeight || "";
	const matchingPages = [];
	data.allWpFaq.nodes.forEach((element) => {
		if (element.faqFields.page.includes(page)) {
			matchingPages.push(element);
		}
	});

	return (
		<>
			{matchingPages.length > 0 && (
				<section className="py-5 py-lg-7 position-relative">
					<div
						style={{ zIndex: "-3" }}
						className=" h-100 w-100 position-absolute"
					></div>
					<Container>
						<Row className={` `}>
							<Col className="">
								<h2 className="text-primary ps-2 ps-md-0 display-5 mb-3">
									{heading ?? "FAQ’S"}
								</h2>
							</Col>
						</Row>
						<Row className="align-items-center px-2 px-md-0 gx-8 justify-content-end">
							<Col className="ps-6" lg={7}>
								{page !== "All" && (
									<Row className="pb-5">
										{data.allWpFaq.nodes
											.filter((item, index) =>
												item.faqFields.page.includes(page)
											)
											.map((faq, index) => (
												<Col xs={12} className="my-1 mx-0 px-0 ">
													<Accordion>
														<Card className="border-0">
															<Card.Header className="border-0  px-0 p-0 m-0">
																<ContextAwareToggle
																	eventKey="0"
																	className=" border-0 "
																>
																	<div className="d-flex align-items-center">
																		<p className="pb-0 mb-0 quicksand">
																			{faq.faqFields.question}
																		</p>
																	</div>
																</ContextAwareToggle>
															</Card.Header>
															<hr
																style={{ opacity: 1 }}
																className="bg-primary "
															/>
															<Accordion.Collapse eventKey="0">
																<Card.Body className="pt-0 ">
																	<Row>
																		<Col
																			className="ps-1 fw-light"
																			xs={{ span: 12 }}
																			md={{ span: 10 }}
																			xl={{ span: 8 }}
																		>
																			<span style={{ fontSize: "100%" }}>
																				{parse(faq.faqFields.answer)}
																			</span>
																		</Col>
																	</Row>
																</Card.Body>
															</Accordion.Collapse>
														</Card>
													</Accordion>
												</Col>
											))}
									</Row>
								)}
								{page === "All" && (
									<Row className="pb-5">
										{data.allWpFaq.nodes.map((faq, index) => (
											<Col xs={12} className="my-1 mx-0 px-0 ">
												<Accordion className="bg-light-grey">
													<Card className="border-0 bg-light-grey">
														<Card.Header className="border-0  px-0 p-0 m-0">
															<ContextAwareToggle
																eventKey="0"
																className=" border-0 "
															>
																<div className="d-flex align-items-center">
																	<p className="pb-0 mb-0 ssp-bold">
																		{faq.faqFields.question}
																	</p>
																</div>
															</ContextAwareToggle>
														</Card.Header>
														<Accordion.Collapse eventKey="0">
															<Card.Body className="pt-0 bg-light-grey ">
																<Row>
																	<Col
																		className="ps-1 fw-light"
																		xs={{ span: 11, offset: 1 }}
																	>
																		<span style={{ fontSize: "80%" }}>
																			{parse(faq.faqFields.answer)}
																		</span>
																	</Col>
																</Row>
															</Card.Body>
														</Accordion.Collapse>
													</Card>

													<hr style={{ opacity: 1 }} className="bg-black " />
												</Accordion>
											</Col>
										))}
									</Row>
								)}
							</Col>
							<Col className="text-center d-none d-lg-block" lg={5}>
								<GatsbyImage
									style={{ height: height }}
									className=""
									image={image}
									alt={imageAlt}
								/>
							</Col>
						</Row>
					</Container>
				</section>
			)}
		</>
	);
};

export default Faq;

function ContextAwareToggle({ children, eventKey, callback }) {
	const currentEventKey = useContext(AccordionContext);
	const [turnArrow, setTurnArrow] = useState(false);

	const decoratedOnClick = useAccordionButton(eventKey, () => {
		callback && callback(eventKey);
		setTurnArrow(!turnArrow);
	});

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Card.Header
			className="border-0"
			style={{
				color: isCurrentEventKey ? "white" : "black",
				backgroundColor: isCurrentEventKey ? "black" : "white",
			}}
			onClick={decoratedOnClick}
		>
			<Container className="pe-0 ps-1" fluid={true}>
				<Row className="align-items-center">
					<Col
						className="d-flex justify-content-start align-items-center ps-0"
						xs={11}
					>
						{children}
					</Col>
					<Col xs={1} className="d-flex px-0  justify-content-end">
						<MdKeyboardArrowUp
							className={`position-relative fs-3 text-primary cheveron  ${
								turnArrow ? "cheveron-down" : ""
							}`}
						/>
					</Col>
				</Row>
			</Container>
		</Card.Header>
	);
}
