import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				jeffVanImg: wpMediaItem(title: { eq: "Home-png" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				schemaLogo: wpMediaItem(title: { eq: "tops-logo" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		`
	);

	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		name: "Tops Garden Buildings",
		legalName: "Tops (GTD) Limited",
		description:
			"Tops is a friendly, family-run firm based in Lower Dicker, Hailsham. We have been delivering a wide range of quality and aesthetically appealing garden buildings for over 20 years. We take great care and pride in our work and provide innovative solutions. We have thousands of happy customers and enjoy a favourable reputation in the Sussex area. Our expertise enables us to offer a complete service that is second to none. Why not call us to find out how we can help you?",
		image: `${data.jeffVanImg.localFile.publicURL}`,
		"@id": "https://www.topsgardenbuildings.com",
		slogan: "Conservatories and garden buildings",
		url: "https://www.topsgardenbuildings.com",
		telephone: "01323 441616",
		priceRange: "$$$",
		areaServed: [
			{
				"@type": "city",
				name: "East Sussex",
				sameAs: "https://en.wikipedia.org/wiki/East_Sussex",
			},
		],
		email: "sales@topsgreenhouses.com",
		currenciesAccepted: "GBP",
		numberOfEmployees: "4",
		location: {
			"@type": "City",
			name: "East Sussex",
			sameAs: "https://en.wikipedia.org/wiki/East_Sussex",
		},
		foundingLocation: {
			"@type": "City",
			name: "East Sussex",
			sameAs: "https://en.wikipedia.org/wiki/East_Sussex",
		},
		address: {
			"@type": "PostalAddress",
			streetAddress:
				"Tops Conservatories & Garden Buildings, Blackbrooks Garden Centre",
			addressLocality: "Hailsham",
			addressRegion: "East Sussex",
			postalCode: "BN27 4BJ",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: 50.88130276987661,
			longitude: 0.213429340510112,
		},
		hasMap:
			"https://www.google.com/maps/place/Blackbrooks+Garden+Centre/@50.8811471,0.2112621,17z/data=!3m1!4b1!4m5!3m4!1s0x47df6f13b6d67913:0x117f37b65cf7eaf2!8m2!3d50.8811511!4d0.2134325",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Monday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Tuesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Wednesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Thursday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Friday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Saturday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "16:30",
				dayOfWeek: "https://schema.org/Sunday",
				opens: "10:30",
			},
		],
		founder: {
			"@type": "Person",
			name: "David Gray",
			id: "https://www.topsgardenbuildings.com/#DavidGray",
			url: "https://www.topsgardenbuildings.com/#DavidGray",
			description: "Director",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "0",
			ratingValue: "4.9",
			reviewCount: "125",
			url: "https://www.topsgardenbuildings.com",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.topsgardenbuildings.com/contact",
			url: "https://www.topsgardenbuildings.com/contact",
			image: "https://www.topsgardenbuildings.com",
			name: "David Gray",
			description: "Contact Centre",
			telephone: "01323 441616",
			contactType: "Contact Centre",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "East Sussex",
					sameAs: "https://en.wikipedia.org/wiki/East_Sussex",
				},
			],
		},
		logo: `${data.schemaLogo.localFile.publicURL}`,
		sameAs: ["https://instagram.com/topsgardenbuildings?igshid=YmMyMTA2M2Y="],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
