import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Link } from "gatsby";
import { Button } from "react-bootstrap";
import gsap from "gsap";

const Hero = ({
  title,
  description,
  backgroundImage,
  ctaButton,
  ctaButton2,
}) => {
  const pluginImage = getImage(backgroundImage);

  useEffect(() => {
    gsap.to(".heading-fade", {
      opacity: 1,
      duration: 1,
      delay: 0.5,
      transform: "scale(1)",
    });
    gsap.to(".text-fade", {
      opacity: 1,
      duration: 1,
      delay: 0.8,
      transform: "scale(1)",
    });
    gsap.to(".button-fade-1", {
      opacity: 1,
      duration: 1,
      delay: 1.1,
      transform: "scale(1)",
    });
    gsap.to(".button-fade-2", {
      opacity: 1,
      duration: 1,
      delay: 1.4,
      transform: "scale(1)",
    });
  }, []); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

  return (
    <BgImage className=" " image={pluginImage}>
      <Container className="py-5 pt-lg-6 pb-lg-10">
        <Row
          className="
                "
        >
          <Col lg={8} xl={7} className="">
            <div
              className="w-100 pt-4 pb-5 px-3 px-md-5 position-relative"
              style={{ background: "rgba(255, 255, 255,0.90)" }}
            >
              <h1
                style={{
                  lineHeight: "130%",
                  opacity: 0,
                  transform: "scale(0.95)",
                }}
                className=" mb-3 mt-3  text-primary heading-fade"
              >
                {title}
              </h1>
              <div
                style={{
                  fontSize: "100%",
                  opacity: 0,
                  transform: "scale(0.95)",
                }}
                className="mb-0 text-black text-fade hero-content"
              >
                {description}
              </div>
              {ctaButton && ctaButton.url && (
                <Button
                  style={{ opacity: 0, transform: "scale(0.95)" }}
                  className="mt-4 me-md-4 w-100 w-md-auto button-fade-1"
                  as={Link}
                  to={ctaButton?.url}
                  variant="primary"
                  target={ctaButton?.target ?? "_blank"}
                >
                  {ctaButton?.title}
                </Button>
              )}

              {ctaButton2 && ctaButton2.url && (
                <Button
                  style={{ opacity: 0, transform: "scale(0.95)" }}
                  className="mt-2 mt-md-4 w-100 hover-dark-green w-md-auto button-fade-2"
                  as={Link}
                  to={ctaButton2?.url}
                  variant="outline-primary"
                  target={ctaButton2?.target ?? "_blank"}
                >
                  {ctaButton2?.title}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </BgImage>
  );
};

export default Hero;
