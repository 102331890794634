import React from "react";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Container, Row } from "react-bootstrap";

const ServiceBoxHome = ({
	title,
	image,
	uri,
	height,
	span,
	mdSpan,
	minHeight,
  imageAlt = ""
}) => {
	const medSpan = mdSpan || 6;
	const minHt = minHeight || "";
	return (
		<Col style={{ minHeight: "100%" }} md={medSpan} lg={span}>
			<Link className="white-link-green" to={uri}>
				<div className="position-relative">
					<GatsbyImage
						loading="eager"
						style={{ height: height, minHeight: minHt, zIndex: 1 }}
						className="w-100 position-relative"
						image={image}
            alt={imageAlt}
					/>
					<Container
						style={{ zIndex: 2 }}
						className="h-100 top-0 start-0 position-absolute"
					>
						<Row className="align-items-end h-100">
							<Col
								className="py-2 "
								style={{ background: "rgba(0, 0, 0,0.4)" }}
								xs={12}
							>
								<h3 className="white-link-green fs-4 mb-0 pb-0">{title}</h3>
							</Col>
						</Row>
					</Container>
				</div>
			</Link>
		</Col>
	);
};

export default ServiceBoxHome;
