import { useStaticQuery, graphql, Link } from "gatsby";
import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import ShowroomSection from "../components/showroom-section";
import Reviews from "../components/reviews";
import Hero from "../components/hero";
import useOnScreen from "../components/use-on-screen";
import CountUp from "react-countup";
import gsap from "gsap";
import ServiceBoxHome from "../components/service-box-home";
import uuid4 from "uuid4";
import { Button } from "react-bootstrap";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-ntml-parser";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wpOffers {
        offersFields {
          title
          description
          button {
            text
            link
          }
          offerCards {
            title
            description
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "home" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        homePageFieldGroups {
          bannerSection {
            heading
            description
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            cta1 {
              title
              target
              url
            }
            cta2 {
              title
              target
              url
            }
          }
          businessSection {
            heading
            description
            backgroundImage {
              node {
                altText
                mediaItemUrl
              }
            }
            business {
              heading {
                title
                target
                url
              }
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          reviewsSection {
            heading
          }
          imageSection {
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          faqSection {
            heading
            imagae {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          aboutSection {
            heading
            description
            stats {
              count
              heading
              countUnit
            }
          }
          ctaSection {
            heading
            description
            cta {
              title
              target
              url
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, homePageFieldGroups },
  } = data;

  const {
    bannerSection,
    businessSection,
    reviewsSection,
    imageSection,
    faqSection,
    aboutSection,
    ctaSection
  } = homePageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
        },
      },
    ],
  };
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const onScreen1 = useOnScreen(ref1, "0px");
  const onScreen2 = useOnScreen(ref2, "100px");
  const onScreen3 = useOnScreen(ref3, "100px");
  useEffect(() => {
    if (onScreen2 === true) {
      gsap.to(
        ".card-row-1",

        { opacity: 1, top: "0rem", duration: 2 }
      );
    }
  }, [onScreen2]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED
  useEffect(() => {
    if (onScreen3 === true) {
      gsap.to(
        ".card-row-2",

        { opacity: 1, top: "0rem", duration: 2 }
      );
    }
  }, [onScreen2]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <LocalBusinessSchema />
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout showTest={true}>
          {bannerSection &&
            !checkPropertiesForNull(bannerSection, ["heading"]) && (
              <Hero
                backgroundImage={
                  bannerSection.backgroundImage.node?.localFile.childImageSharp
                    .gatsbyImageData
                }
                title={bannerSection?.heading}
                description={
                  <SafeHtmlParser htmlContent={bannerSection?.description} />
                }
                ctaButton={bannerSection?.cta1}
                ctaButton2={bannerSection?.cta2}
              />
            )}
          {businessSection &&
            !checkPropertiesForNull(businessSection, ["heading"]) && (
              <section className="pb-5 pt-5 pt-lg-9 pb-lg-7">
                <Container>
                  <Row>
                    <Col className="text-lg-center">
                      <div className="d-inline-block position-relative">
                        <h2
                          style={{ zIndex: 2 }}
                          className=" mb-4 text-primary  d-inline-block fs-1 text-lg-center position-relative "
                        >
                          {businessSection?.heading}
                        </h2>
                        <img
                          className="position-absolute d-none d-lg-block"
                          style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
                          quality="100"
                          src={
                            businessSection?.backgroundImage?.node?.mediaItemUrl
                          }
                          placeholder="blurred"
                          width={130}
                          formats={["auto", "webp"]}
                        />
                      </div>
                      <SafeHtmlParser
                        htmlContent={businessSection?.description}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {businessSection &&
            !checkPropertiesForNull(businessSection, ["business"]) && (
              <section id="introduction" className="pb-5 pb-lg-7 ">
                <Container>
                  <Row className="g-2 d-lg-none">
                    {businessSection.business.map((businessItem, i) => (
                      <ServiceBoxHome
                        key={uuid4()}
                        animClass={i < 3}
                        span={4}
                        height="20rem"
                        uri={businessItem?.heading?.url}
                        title={businessItem?.heading?.title}
                        image={
                          businessItem.image.node?.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        imageAlt={businessItem.image.node?.altText}
                      />
                    ))}
                  </Row>

                  <Row
                    ref={ref2}
                    style={{
                      minHeight: "100%",
                      position: "relative",
                      top: "5rem",
                      opacity: 0,
                    }}
                    className="g-2 d-none card-row-1 d-lg-flex"
                  >
                    {businessSection.business.map((businessItem, i) => (
                      <ServiceBoxHome
                        animClass={i < 3}
                        span={4}
                        height="20rem"
                        uri={businessItem?.heading?.url}
                        title={businessItem?.heading?.title}
                        image={
                          businessItem.image.node?.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        imageAlt={businessItem.image.node?.altText}
                      />
                    ))}
                  </Row>
                </Container>
              </section>
            )}
          <section id="offers" className="py-5 py-lg-7 ">
            <Container>
              <Row>
                <Col className="text-center">
                  <div className="d-inline-block position-relative">
                    <h2
                      style={{ zIndex: 2 }}
                      className=" mb-4 text-primary  d-inline-block fs-1 text-lg-center position-relative "
                    >
                      {data.wpOffers.offersFields.title}
                    </h2>
                    <StaticImage
                      className="position-absolute d-none d-lg-block"
                      style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
                      quality="100"
                      src="../images/single-leaf.svg"
                      placeholder="blurred"
                      width={130}
                      formats={["auto", "webp"]}
                      alt=""
                    />
                  </div>
                  {data.wpOffers.offersFields.description?.length > 1 && (
                    <p>{data.wpOffers.offersFields?.description}</p>
                  )}
                  <div className="d-block">
                    <Button
                      className="mt-3  w-100 w-md-auto "
                      as={Link}
                      to={data.wpOffers.offersFields.button.link}
                      variant="primary"
                    >
                      {data.wpOffers.offersFields.button.text}
                    </Button>
                  </div>
                  <Row className="justify-content-center mt-3 gy-md-5 gx-6 gy-3">
                    {data.wpOffers.offersFields.offerCards.map(
                      (offer, index) => (
                        <Col
                          style={{ minHeight: "100%" }}
                          className="text-start"
                          md={6}
                          lg={4}
                        >
                          <div
                            style={{ borderRadius: "10px" }}
                            className="bg-light-grey h-100 p-3"
                          >
                            {" "}
                            <h3
                              style={{ zIndex: 2 }}
                              className=" text-primary  fs-2  "
                            >
                              {offer.title}
                            </h3>
                            <p>{offer.description}</p>
                          </div>
                        </Col>
                      )
                    )}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <div id="reviews">
            <Reviews topPadding="pt-7" title={reviewsSection?.heading} />
          </div>
          {imageSection && (
            <section>
              <GatsbyImage
                className="w-100 "
                image={
                  imageSection.image.node?.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={imageSection.image.node?.altText}
              />
            </section>
          )}

          <Faq
            imageHeight="25rem"
            page="Home"
            image={
              faqSection.imagae?.node?.localFile.childImageSharp.gatsbyImageData
            }
            imageAlt={faqSection.imagae?.node?.altText}
            heading={faqSection?.heading}
          />

          {aboutSection &&
            !checkPropertiesForNull(aboutSection, ["business"]) && (
              <section className="py-5 py-lg-6 bg-primary">
                <Container>
                  <Row ref={ref1} className="justify-content-center">
                    <Col className="text-center" xl={9}>
                      <h2 className="text-white fs-1">
                        {aboutSection?.heading}
                      </h2>
                      <div className="text-white ">
                        <SafeHtmlParser
                          htmlContent={aboutSection?.description}
                        />
                      </div>
                      {aboutSection.stats && aboutSection.stats.length > 0 && (
                        <Row className="pt-5 g-5">
                          {aboutSection.stats.map((item) => (
                            <Col xs={6} md={3} key={uuid4()}>
                              <p className="quicksand text-white">
                                <span className="display-3">
                                  {" "}
                                  {onScreen1 && <CountUp end={item?.count} />}
                                </span>{" "}
                                <span className="fs-1 text-secondary">{item?.countUnit}</span>
                              </p>
                              <p className="text-white">
                                <SafeHtmlParser htmlContent={item?.heading} />
                              </p>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          <ShowroomSection
            title={ctaSection?.heading}
            description={<SafeHtmlParser htmlContent={ctaSection?.description} />}
            ctaButton={ctaSection?.cta}
          />
          {/* <ThreeImageSection
						title="Need a made-to-measure timber shed?"
						text="The majority of our sheds can be made to measure so if you have a tight or irregular space, let us know and we can arrange a site visit to quote for a shed that is the ideal fit for your space."
						imageHeight="15rem"
						image1={data.timberImg1.localFile.childImageSharp.gatsbyImageData}
						image1Alt={data.timberImg1.altText}
						image2={data.timberImg2.localFile.childImageSharp.gatsbyImageData}
						image2Alt={data.timberImg2.altText}
						image3={data.timberImg3.localFile.childImageSharp.gatsbyImageData}
						image3Alt={data.timberImg3.altText}
					/> */}
          {/* <ImageSide
						mobileImageHeight="20rem"
						svg={
							<StaticImage
								className="position-absolute d-none d-lg-block"
								style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
								quality="100"
								src="../images/single-leaf.svg"
								placeholder="blurred"
								width={130}
								formats={["auto", "webp"]}
							/>
						}
						svgPos="1rem"
						title="Concrete bases"
						text={
							<span>
								<p>
									The majority of our buildings are assembled onto a concrete or
									paving slab base, but don’t worry if you don’t currently have
									a suitable base for your shed/greenhouse or other garden
									building. Our team of fitters can create a custom-made
									concrete base that is the right size for your needs.
								</p>{" "}
								<p>
									{" "}
									Contact us today to book an appointment with our base layer to
									discuss a new base or extending or re-laying an existing base.
								</p>
								<Button
									className="mt-4 w-100 w-md-auto"
									as={Link}
									to="/contact-us"
									variant="primary"
								>
									FIND US
								</Button>
							</span>
						}
						imageHeight="25rem"
						image={data.faqImg.localFile.childImageSharp.gatsbyImageData}
						imageAlt={data.faqImg.altText}
					/>
					<PriceListSection
						bgColour="bg-light-grey"
						mobileImageHeight="20rem"
						title="Types of Summerhouses"
						text={
							<>
								<p>
									Our extensive range of timber summerhouses includes pent,
									apex, octagonal and pyramidal roof designs. You also have a
									range of roofing and glazing options to choose from and you
									can choose to either have a veranda or not.
								</p>
								<p>
									You can trust us to design the perfect summerhouse for your
									garden.
								</p>
							</>
						}
						imageHeight="25rem"
						image={data.faqImg.localFile.childImageSharp.gatsbyImageData}
						imageAlt={data.faqImg.altText}
					/>
					<OpeningBanner />
					<PriceListCarousal
						bgColour="bg-light-grey"
						mobileImageHeight="20rem"
						title="Types of Greenhouses"
						text={
							<>
								<h3 className=" mb-4 text-primary fs-2">Aluminium</h3>
								<p>
									We offer a wide range of aluminium greenhouse from reputable
									brands such as Halls, Elite and Robinson. This means we can
									provide a wide range of styles, sizes and finishes to match
									your taste and budget so you end up with your perfect
									greenhouse.
								</p>
							</>
						}
						text2={
							<>
								<h3 className=" mb-4 text-primary fs-2">Timber</h3>
								<p>
									We specialise in cedar greenhouses from Alton and Thermowood
									Greenhouses from Swallow. These finishes are both a
									low-maintenance option allowing you more time to enjoy
									pottering in your greenhouse.
								</p>
							</>
						}
						imageHeight="25rem"
						image={data.img1.localFile.childImageSharp.gatsbyImageData}
						imageAlt={data.img1.altText}
						image2={data.img2.localFile.childImageSharp.gatsbyImageData}
						imageAlt2={data.img2.altText}
					/> */}
        </Layout>
      </div>
    </>
  );
};

export default IndexPage;
