import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";
import { Link, useStaticQuery, graphql } from "gatsby";
import useOnScreen from "./use-on-screen";
import gsap from "gsap";

const ShowroomSection = ({ title, description, ctaButton }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: wpMediaItem(title: { eq: "Tops-fadedlogo" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  const refSr = useRef();
  const showRoom = useOnScreen(refSr, "0px");

  useEffect(() => {
    if (showRoom === true) {
      gsap.to(".image-fade", {
        opacity: 1,
        duration: 1,
        top: "0rem",
      });
      gsap.to(".heading-fade", {
        opacity: 1,
        duration: 1,
        delay: 0.3,
        top: "0rem",
      });
      gsap.to(".text-fade", {
        opacity: 1,
        duration: 1,
        delay: 0.6,
        top: "0rem",
      });
      gsap.to(".button-fade", {
        opacity: 1,
        duration: 1,
        delay: 0.9,
        top: "0rem",
      });
      gsap.to(".heading-fade-2", {
        opacity: 1,
        duration: 1,
        delay: 0.3,
        transform: "scale(1)",
      });
      gsap.to(".text-fade-2", {
        opacity: 1,
        duration: 1,
        delay: 0.6,
        transform: "scale(1)",
      });
      gsap.to(".button-fade-2", {
        opacity: 1,
        duration: 1,
        delay: 0.9,
        transform: "scale(1)",
      });
    }
  }, [showRoom]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

  return (
    <section ref={refSr} className="py-5  position-relative">
      <Container className="d-none d-md-block">
        <Row>
          <Col>
            <GatsbyImage
              loading="eager"
              style={{ maxWidth: "20rem", opacity: 0, top: "-5rem" }}
              className="position-relative image-fade "
              image={data.logo.localFile.childImageSharp.gatsbyImageData}
              alt=""
            />
          </Col>
        </Row>
      </Container>

      <Container
        style={{ zIndex: "2" }}
        className="position-absolute start-50 d-none d-md-block top-50 translate-middle"
      >
        <Row className="justify-content-center">
          <Col lg={8}>
            <h2
              style={{ opacity: 0, top: "-3rem" }}
              className="text-primary heading-fade pb-3 position-relative"
            >
              {title ?? "VISIT OUR MODERN SHOWROOM TODAY"}
            </h2>
            <div
              style={{ opacity: 0, top: "-3rem" }}
              className="pb-3 text-fade position-relative"
            >
              {description}
            </div>
            {ctaButton && ctaButton.url && (
              <Button
                style={{ opacity: 0, top: "-3rem" }}
                className="w-100 w-md-auto button-fade position-relative"
                as={Link}
                to={ctaButton?.url}
                variant="primary"
                target={ctaButton?.target ?? "_blank"}
              >
                {ctaButton?.title}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
      <Container className=" d-md-none ">
        <Row className="justify-content-center">
          <Col lg={8}>
            <h2
              style={{ opacity: 0, transform: "scale(0.95)" }}
              className="text-primary heading-fade-2 pb-3"
            >
              {title ?? "VISIT OUR MODERN SHOWROOM TODAY"}
            </h2>
            <div
              style={{ opacity: 0, transform: "scale(0.95)" }}
              className="pb-3 text-fade-2"
            >
              {description}
            </div>
            {ctaButton && ctaButton.url && (
              <Button
                style={{ opacity: 0, transform: "scale(0.95)" }}
                className="w-100 w-md-auto button-fade-2"
                as={Link}
                to={ctaButton?.url}
                variant="primary"
                target={ctaButton?.target ?? "_blank"}
              >
                {ctaButton?.title}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ShowroomSection;
